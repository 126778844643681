

import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Teens = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>Teen's Seminar</h3>
<em>"The Real Dope"</em><br/>
<p>Real Answers to Tough Questions<br/><br/>

Rabbi Fine will conduct sessions that provide meaningful and practical answers to the burning questions your teens are asking. He will encourage them to turn to their families for the answers to these questions. He will encourage a stronger link with the family.<br/><br/>

"I've made a terrible mistake. It's really going to hurt my parents. I'm afraid to tell them. Should I?"<br/><br/>

"I think one of my close friends is in real trouble with drugs. Should I do anything about it?"<br/><br/>

"I'm having trouble in my math class and the teacher only cares about the kids who are doing well. How am I going to get through the class?"<br/><br/>

"I heard there was a wild party and one of the guys got a friend of mine drunk and I think he had sex with her. What should I do?"<br/><br/>

"If God cares so much about the world, why is there so much suffering?"<br/><br/>

Yehudah Fine has worked with teens from every socio-economic class. His training and experience allows him to translate questions of values and beliefs into real-world terms that can drastically change lives.<br/><br/>
</p>
    </Layout>
  )
}

export default Teens

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`